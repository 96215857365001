import React from 'react'
import './Button.css'

const STYLES = ['btn--outline', 'btn--primary']

const SIZES  = ['btn--medium', 'btn--large', 'btn--mobile', 'btn--wide']

const COLOR  = ['primary', 'blue', 'red', 'green']


const Button = ({children,type,onClick,buttonStyle,buttonSize,buttonColour}) => {

    const checkButtonStyle  =  STYLES.includes(buttonStyle) ? buttonStyle     : STYLES[0]

    const checkButtonSize   =  SIZES.includes(buttonSize)  ?  buttonSize     :  SIZES[0]

    const checkButtonColor  =  COLOR.includes(buttonColour) ? buttonColour   :  null


    return(

        <button className = {` btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`} onClick = {onClick} type = {type} >

            {children}

        </button> 

    )
}

export default Button