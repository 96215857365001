import React, { Component } from 'react'

export default class DashboardFooter extends Component {

    render() {

        return (

            <div>
                <footer className="main-footer">
                    <strong>Copyright © Sproutpay <a href="https://sproupay.net">Sproutpay</a>.</strong>
                    All rights reserved.
                    <div className="float-right d-none d-sm-inline-block">
                       
                    </div>
                </footer>
            </div>

        )
    }
}
